import React from "react";
import { Helmet } from "react-helmet";

import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";

import Layout from "../components/Layout";

const SITE = "Valle Guadalupe Baja";
const DOMAIN = "valleguadalupebaja.com";
<br />;

const TermsPage = () => {
    const useStyles = makeStyles((theme) => ({
        textblock: {
            marginTop: 30,
        },
    }));
    const styles = useStyles();
    return (
        <Layout pageName="termsandconditions">
            <Helmet>
                <title>General Terms & Conditions</title>
            </Helmet>
            <Container align="justify" maxWidth="md">
                <Box sx={{ mt: 4 }}>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="subtitle1"
                    >
                        Last Modified January 19th 2022
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="subtitle1"
                    >
                        These General Terms and Conditions are subdivided into:
                        <br />
                        <br />
                        I. {SITE} Terms of Use and <br />
                        II. General Terms and Conditions of the Supplier of
                        tours and other tourist services (referred company)
                        <br />
                        <br />
                        Within the scope of an intermediary service, {SITE}{" "}
                        shall provide the visitors and Suppliers of tours or
                        tourist services with this booking Platform.
                        Furthermore, {SITE} shall also provide this booking
                        Platform to certain cooperating partners (sub-agent/
                        distribution partner) for the booking of the Supplier’s
                        tourist services. The use of this Platform by sub-agents
                        or distribution partner requires a separate contractual
                        agreement and is not permitted without the prior written
                        authorization of {SITE}. Contracts for tourist services
                        are to be concluded directly between the users of this
                        booking Platform and the Suppliers of tours or other
                        tourist services. If users book services via a linked
                        partner Platform or if a sub-intermediary makes a
                        booking on behalf of a user of a connected partner
                        Platform, a corresponding contract shall be concluded
                        directly between this user and the Supplier. {SITE} is
                        not a contract party with regards to the tourist
                        services offered on this booking Platform.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h6">
                        I. {SITE} Terms of Use
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        1. Who operates this website?
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        This website (including sub-sites and including text,
                        images, videos, software, products, services and other
                        information contained in or presented on the website;
                        all together the "Website") is provided by {SITE}, 4876
                        Santa Monica Ave Ste 236, San Diego, CA 92107 USA. You
                        can contact us by email (https://www.{DOMAIN}/contact)
                        or by phone using these numbers:
                        <br />
                        <br />
                        USA: +1 619-361-8571
                        <br />
                        <br />
                        Bookings through the Platform are subject to the General
                        Terms and Conditions of {SITE} as well as the General
                        Terms and Conditions of the Supplier of tours and other
                        tourist.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        2. Application of these Terms of Use
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        These terms of use (the "Terms of Use"), together with
                        our privacy policy (the "Privacy Policy"), apply to any
                        use of the Website. Visitors of the Website ("User" or
                        "you") may use the Website only on condition that they
                        accept the Terms of Use and read and understand the
                        Privacy Policy. Any further use of the Website or any
                        part of it means you have read and understood the Terms
                        of Use and the Privacy Policy, and agree to be bound by
                        all parts of the Terms of Use.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        3. No offer
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The information on this Website is for general
                        informational purposes only. Information on this Website
                        does not constitute an offer binding to us. Binding
                        agreements with suppliers of activities available on the
                        Website require a booking request through the {SITE}{" "}
                        Platform and the supplier's acceptance of the booking
                        request according to the General Terms and Conditions of{" "}
                        {SITE}.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        4. No warranty
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        While {SITE} tries to ensure that the information in or
                        through the Website is accurate, it does not provide any
                        warranties, express or implied, in relation to its
                        correctness, completeness, current, reliability,
                        suitability for any purpose or otherwise (including for
                        any information provided by third parties). {SITE} may
                        change, add or remove information on the Website and its
                        structure and functions at any time at its sole
                        discretion, without specifically informing of any such
                        change, and without removing outdated information or
                        characterizing it as such. {SITE} may also block Users
                        from accessing the Website or parts of it, or requires
                        certain conditions to be fulfilled for such access.{" "}
                        {SITE} does not provide any warranties, express or
                        implied, in relation to the availability of the Website
                        or its functions, that the Website is free from defects,
                        or that the Website and the infrastructure on which it
                        runs is free from viruses and other harmful software.
                        Moreover, {SITE} does not guarantee that information
                        available on the Website has not been altered through
                        technical defects or by unauthorized third parties.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        5. Limitation of liability
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} excludes its liability, and that of its agents
                        and independent contractors, and its and their employees
                        and officers, and its sub-agents or distribution
                        partners for damages relating to your access to (or
                        inability to access) the Website, or to any errors or
                        omissions, or the results obtained from the use, of the
                        Website, whatever the legal basis of such liability
                        would be, except liability for damages caused willfully
                        or through gross negligence, and only to the extent
                        permitted by applicable law. Restrictions of liability
                        do not apply within the scope of guarantees issued, in
                        the event of an injury to life, limb or health or for
                        claims based on product liability regulation.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        6. Third party content, links to other websites
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} does not assume any responsibility for third
                        party content (including any activities available for
                        booking requests, and information relating to such
                        activities) that may be available through the Website,
                        and for content linked to the Website or which are
                        linked to from it or referred to. {SITE} does not
                        recommend or endorse such content, and will not have any
                        liability relating to it. Where {SITE}
                        links to third party websites, you use such websites at
                        your own risk. We recommend reading the policies of
                        these websites and review how these websites may process
                        personally identifiable data relating to you. If you
                        think we have illicit content on the Website, please
                        contact us at https://www.{DOMAIN}/contact.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        7. Limited {SITE} App license
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} hereby grants to you a non-exclusive,
                        non-transferable, non-sublicensable, revocable,
                        royalty-free, worldwide right to use the {SITE}
                        App according to these Terms of Use and provided that
                        you are and will always be in compliance with these
                        Terms of Use. You may (i) only use the {SITE} App in
                        object code form and for your personal purposes (if you
                        are a consumer) or for your internal business purposes
                        (if you are a business); (ii) only use such number of
                        copies of the {SITE} App and make such number of backup
                        copies of the {SITE} App as may be necessary for its
                        lawful use; (iii) not nor permit any third party to
                        copy, adapt, reverse engineer, decompile, disassemble,
                        modify, adapt or make error corrections to the {SITE}{" "}
                        App in whole or in part; (iv) not rent, lease,
                        sub-license, loan, translate, merge, adapt or modify the{" "}
                        {SITE} App or any associated documentation; (v) not
                        disassemble, de-compile, reverse engineer or create
                        derivative works based on the whole or any part of the{" "}
                        {SITE} App nor attempt to do any such things.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        8. Intellectual property rights
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        As between you and {SITE}, the Website is and remains
                        protected by copyright and/or any other intellectual
                        property rights (including protection granted through
                        unfair competition law). You acquire no rights in the
                        Website, and in any names, trade names, and distinctive
                        signs of any nature (including trademarks) published on
                        the Website. You may access and view the Website, but
                        not incorporate it into other websites, and not copy,
                        present, license, publish, download, upload, send or
                        make it perceptible in any other way without our prior
                        written consent.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        9. Amendments to the Terms of Use
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        {SITE} may amend these Terms of Use at any time and with
                        immediate effect. If we make amendments, they apply as
                        of the date of their publication on the Website.
                        {SITE} expects you to regularly refer to this section to
                        make sure you are familiar with the applicable Terms of
                        Use. Any further use of the Website following such
                        amendments means you consent to the amendment.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        10. Applicable law and jurisdiction
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The law of the California in the United States of
                        America shall apply. If you have placed the order, as
                        the consumer, and at the time of the order your normal
                        place of residence is in another country, the
                        application of the mandatory legal provisions of this
                        country remain unaffected by the choice of law in clause
                        1. The exclusive place of jurisdiction is San Diego
                        California, provided the user is a tradesperson or does
                        not have a permanent place of residence in United States
                        when the suit is filed. The legally-binding places of
                        jurisdiction remain unaffected by this.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h6">
                        II. General Terms and Conditions of the Supplier of
                        tours and other tourist services
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        1. Introduction
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        Part II of these General Terms and Conditions applies to
                        all contracts concluded between the Suppliers and the
                        users via the {SITE} Platform. This also applies, if
                        access to the {SITE} Platform is provided via a
                        cooperating partner (sub- agent, distribution partner).
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        2. Truthful details
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The details transferred via the {SITE} Platform must be
                        truthful. The Supplier reserves the right to withdraw
                        from the contract or to exclude users from participating
                        in the service and to demand compensation amounting to
                        the cancellation costs as per Section 11 of part II of
                        the General Terms and Conditions of Supplier of tours
                        and other tourist services, if defective details are
                        provided by the user.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        3. Arrival at meeting point and compliance with
                        conditions
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        You are responsible for arriving on time at the notified
                        meeting point. If you are travelling to an activity from
                        abroad, you are responsible for having the necessary
                        travel documents (passport etc.), and for observing the
                        health regulations etc.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        4. Additional terms and conditions
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        If Supplier includes in addition to these General Terms
                        and Conditions of Supplier of tours and other tourist
                        services of Part II further participation or transport
                        or other terms in a contract with you, you are
                        responsible for observing these additional terms. The
                        Supplier reserves the right to exclude participants from
                        the activity if they do not meet these conditions.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        5. Binding nature of the request
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The contract between you or the user you have referred,
                        and the Supplier comes into effect upon the acceptance
                        of your binding request by the Supplier. When you book a
                        tour or another activity, you make the Supplier a
                        binding offer which you, or the user you have referred,
                        are bound to for five working days. If the Supplier
                        accepts your offer within this period of time, the
                        contract comes into binding effect. You, or the user
                        referred by you, receives notification of this by
                        e-mail.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        6. Payment
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The agreed total price for the service we have provided
                        is due upon the conclusion of the contract.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        7. Notes on the right of revocation
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The Supplier points out that according to legal
                        provisions (section 312, paragraph (2), no. 4, 312g,
                        paragraph 2, clause 1, no. 9, BGB), contracts for
                        tourism services which are concluded as remote selling
                        (i.e. via the {SITE} Platform) do not have a right of
                        revocation, but merely the legal rights to withdraw and
                        cancel the contract.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        8. Self-responsibility for insurance
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The price of the services does not include insurance.
                        You are responsible for providing sufficient insurance
                        protection. The requirement for insurance depends on the
                        activity booked.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        9. Unpaid services
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        If you or the user you have referred do not pay for the
                        booked service according to the agreed due date of
                        payment, although the Supplier is prepared, and able, to
                        perform the contractual service and there is no legal or
                        contractual right of retention for you or the user you
                        have provided, the Supplier is permitted to withdraw
                        from the contract and charge you, or the user you have
                        referred, for the withdrawal costs as per point 11.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        10. Exchange of messages
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        All messages between you, or the user you have referred,
                        and the Supplier shall be done via the {SITE}
                        Platform. In the interest of quick processing, it is
                        recommended to exclusively use the forms provided by
                        {SITE}.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        11. Cancellation guidelines
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        11.1 If you reject (cancel) the activity, the
                        cancellation conditions stated in the product
                        description as well as on your voucher apply.
                        {SITE} advises the user to carefully read the
                        information in the product description.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        11.2 Provided there are no deviating cancellation
                        conditions in the product description of your Supplier,
                        the following cancellation fees will be charged by the
                        Supplier of the tourist services: a. Up to 24 hours
                        before the start of the activity: full refund b. Less
                        than 24 hours before the start of the activity or in the
                        event of a no-show: no refund
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        11.3 You, or the user you have referred, are free to
                        prove to the Supplier that they have not incurred any
                        damage, or substantially less damage than the fee
                        charged by the Supplier.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        11.4 The Supplier reserves the right to request higher,
                        specific compensation instead of the aforementioned
                        flat-rate fees, if the Supplier can prove that it has
                        incurred much higher expenses than the respectively
                        applicable flat-rate fee. In this case the Supplier is
                        obliged to provide specific figures and evidence of the
                        compensation requested, taking into account the saved
                        expenses and any other use of the services.
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        11.5 The refund is done using the same payment method.
                        In the event of credit cards which are charged monthly,
                        the amount shall be credited at the end of the current
                        invoicing period. The exact time of the refund depends
                        on the user’s credit card agreement. The refund by bank
                        transfer shall be done within seven bank working days.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        12. Extraordinary cancellation
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The Supplier can cancel the activity on the agreed date
                        without observing a period of notice, if weather
                        conditions, official measures, strikes or other
                        unforeseeable or unavoidable external conditions (in
                        particular force majeure) make the execution of the
                        activity impossible, make it considerably more difficult
                        or endanger it. In this case the paid price is refunded.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        13. Exclusion of participation
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The Supplier is permitted not to allow you to join an
                        activity, or to exclude you from one, if you do not meet
                        the personal participation requirements, your
                        participation would endanger you or someone else or in
                        any other way make the activity impossible in the
                        long-term. This applies accordingly to a user you have
                        referred. In these cases, the paid price cannot be
                        refunded.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        14. Changes to the program
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        Furthermore, the Supplier reserves the right to make
                        non-essential changes to the program, if this is
                        necessary due to unforeseeable or unavoidable
                        conditions.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        15. Further conditions
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        Further conditions or deviating conditions can be found
                        in the respective tenders.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        16. Time zone
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        For calculations of the time and deadlines, the time
                        zone of the Supplier is authoritative.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        17. Liability of the Supplier of the tour or the tourism
                        service
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        The Supplier is liable in accordance with the applicable
                        law for its services, whereby the liability, if
                        permitted according to the applicable law, is excluded.
                    </Typography>
                    <Typography className={cx(styles.textblock)} variant="h5">
                        18. Change to these General Terms and Conditions
                    </Typography>
                    <Typography
                        className={cx(styles.textblock)}
                        variant="body1"
                    >
                        These Terms and Conditions of the Supplier of tours and
                        other tourist services of Part II can be amended at any
                        time and without notice for future bookings. The
                        respective conditions valid for each individual booking
                        shall apply. The user has no claim for future bookings
                        based on the existing conditions.
                    </Typography>
                </Box>
            </Container>
        </Layout>
    );
};

export default TermsPage;
